// helpers.js most borrow from ErrandHelper.js with ES6 format.
import React from "react";
import update from 'immutability-helper';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { doNothing } from '../constants';
import { REVIEW } from '../path';
import {
	addressValidation as _addressValidation,
	emailValidation as _emailValidation,
	hasPrefix as hasPrefixHelper,
	hasSuffix as hasSuffixHelper,
	isValidEmail as _isValidEmail,
	isValidMultiEmails as _isValidMultiEmails,
	isValidMultiPhones as _isValidMultiPhones,
	isValidPhoneNo as _isValidPhoneNo,
	validEmailRegex as _validEmailRegex,
	validMultiEmailsRegex as _validMultiEmailsRegex,
	validMultiPhonesRegex as _validMultiPhonesRegex,
	validPhoneRegex as _validPhoneRegex,
	validSipRegex as _validSipRegex,
	validFixedLengthNumericRegex as _validFixedLengthNumericRegex,
	validMultiFixedLengthNumericRegex as _validMultiFixedLengthNumericRegex,
	isValidFixedLengthNumeric as _isValidFixedLengthNumeric,
	isValidMultiFixedLengthNumeric as _isValidMultiFixedLengthNumeric
} from '../helpers';
import {
	CL_OVERVIEW
	, CLOSED
	, M_EXTERNALEXPERT
	, M_MY_EXTERNALEXPERT
	, M_MY_SALUTATION
	, M_MY_SIGNATURE
	, M_REVIEW_KEYWORDS
	, M_SALUTATION
	, M_SIGNATURE
	, M_TEMPLATE
	, M_TEMPLATE_WHATSAPP
	, M_ERRANDINTERNALSTATE
	, CLOSE_BROWSER_MANUALLY
	, PC_REJECT
	, RC_CHAT
	, RC_EMAIL
	, RC_FACEBOOK
	, RC_FB_MESSENGER
	, RC_TW_MESSENGER
	, RC_INSTAGRAM
	, RC_LINE
	, RC_LINKEDIN
	, RC_TWITTER
	, RC_VK
	, RC_SMS
	, RC_VOICE
	, RPLY_COLLABORATE
	, RPLY_GRP_CHAT_COL
	//, DEFAULT_ERRAND_TYPE_ICONS
	, PUZZEL
	, SOLIDUS
	, TELAVOX
	, TOGGLE_COLLAB
	, CLEARIT
	, ENGHOUSE
	, ZISSON
	, ERRAND_HOTKEYS
	, WORKFLOW_HOTKEYS
	, ADMIN_VIEW_MAP
	, M_ROUTING_KEYWORDS
	, M_BLACKLIST
	, M_BLACKLISTRESP
	, M_LLM_TOOL_MANAGER
	, M_ROUTING_AUTOTAGS
	, M_ROUTING_SIP
	, M_CHATWIDGETDL
	, M_FAQWIDGETDL
	, M_VOICEWIDGETDL
	, M_ACCOUNTS
	, M_ACC_EMAIL
	, M_ACC_LINE
	, M_ACC_FACEBOOK
	, M_ACC_INSTAGRAM
	, M_ACC_LINKEDIN
	, M_ACC_JIRA
	, M_ACC_HUBSPOT
	, M_ACC_MSTEAMS
	, M_ACC_GOOGLEREVIEW
	, M_ACC_GOOGLECHAT
	, M_ACC_GOOGLEPLAY
	, M_ACC_SMPP
	, M_ACC_TRUSTPILOT
	, M_ACC_TELEGRAM
	, M_ACC_WHATSAPP
	, M_ACC_TWILIO
	, M_ACC_VIBER
	, M_ACC_SLACK
	, M_ACC_YOUTUBE
	, M_ACC_TWITTER
	, CHANNEL_IDENTIFIER as ch
	, M_QUICK_REPLY
	, CHAT_CKE_PLACEHOLDER_TEXT
	, CHAT_CKEDITOR_ID
} from './constants';
import { PC_NEW_CHART_LAYOUT } from './statisticConstants';
import {
	SIP_DISCONNECTED
	, SIP_UNREGISTER
	, SIP_REGISTER_FAIL
} from './callConstants'
import { isPossiblePhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { CentionFile } from "../../redux/constants/endpoints";
import { webRoot } from "./config";
// import PhoneInput from 'react-phone-number-input';

export const DummyFunction = doNothing;

const reEmptyHTML = /^(<div>[\s\xA0]*<\/div>\s*)+$/, reEmptyPlain = /\s/;
export const parseDate = /^(\d{4})\/(\d{1,2})\/(\d{1,2})\s(\d{1,2}):(\d{1,2})$/;
export const findEmailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
//export const isSipNumberRegex = /^sip:.+/;

// check for empty HTML and return empty string if so else return the original
// html.
export function checkEmptyHTML(html, plain) {
	if(typeof plain === 'undefined') {
		return html;
	}
	const trim = plain.replace(/^\s+|\s+$/, '');
	if((trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) &&
		reEmptyHTML.test(html)) {
		return '';
	}
	return html;
}

// isGroupChatCollaborate is used to seperate collaboration for pre and post
// CCC-4183.
export const isGroupChatCollaborate = reply => reply === RPLY_GRP_CHAT_COL;

export const isChatAndCollaborateInternalChat = (chat, reply) => (
	chat && isGroupChatCollaborate(reply)
);

// isCollaboration return if the reply is any type of collaboration including
// internal chat collaboration.
export const isCollaboration = reply => reply === RPLY_COLLABORATE;

export const chatHasExternalCollaboration = reply => isCollaboration(reply)

export const notChatOrChatInCollborate = (chat, reply) => (
	!chat || chatHasExternalCollaboration(reply)
)

export const isChatAndCollaboration = (chat, reply) => (
	chat && chatHasExternalCollaboration(reply)
)

export function createPromiseControllers() {
	let p = null;
	return [
		force => {
			if (p) {
				if (!force) {
					return p.promise;
				}
				p.reject({cancelled: true});
			}
			p = {};
			p.promise = new Promise((resolve, reject) => {
				p.resolve = resolve;
				p.reject = reject;
			});
			return p.promise;
		}
		, (action, data) => {
			if (!p) {
				return;
			}
			if (action === PC_REJECT) {
				p.reject(data);
			} else {
				p.resolve(data);
			}
			p = null;
		}
	];
}

export function canAcquire(myId, agentId, data) {
	if ((!agentId || agentId === myId) && !data.closed && !data.deleted) {
		return true;
	}
	return false;
}

export function fileNameFromPath(path) {
	const idx = path.lastIndexOf('/');
	if(idx >= 0) {
		if(idx < path.length-1) {
			return path.substr(idx+1);
		} else {
			return '_unknown_';
		}
	}
	return path;
};

export function getChevronIcon(up) {
	if (up) {
		return "icon-chevron-up";
	}
	return "icon-chevron-down"
}

export function isEmptyHTML(html) {
	if(!html) {
		return true;
	}
	return reEmptyHTML.test(html);
}

export function isEmptyRichText(text) {
	if (text.trim() === "") {
		return true;
	}
	const parser = new DOMParser();
	const doc = parser.parseFromString(text, 'text/html');
	return doc.body.innerText.trim() === "";
}

// NOTE: this is different from flux store it never mutate state directly.
// Instead caller should use CHAT_GET_MCOUNT to make sure the chat session
// contains the require mcount object.
export function getMcount(state, sessionId) {
	const chatSession = state.chatSessions[sessionId];
	if(!chatSession || !chatSession.mcount) {
		return 0;
	}
	return Object.keys(chatSession.mcount).length;
}

export const hasPrefix = hasPrefixHelper;

export const hasSuffix = hasSuffixHelper;

export function humanByteSize(showSize) {
	if(!showSize) {
		return '0 KB';
	}
	if(showSize < 1000000) {
		showSize = (showSize/1000).toFixed(2)+" KB";
	} else {
		showSize = (showSize/1000000).toFixed(2)+" MB";
	}
	return showSize;
}

export function isImageFilename(filename) {
	if(typeof filename !== "undefined") {
		const name = filename.toLowerCase();
		if(hasSuffix(name, '.jpg')) {
			return true;
		} else if(hasSuffix(name, '.jpeg')) {
			return true;
		} else if(hasSuffix(name, '.png')) {
			return true;
		} else if(hasSuffix(name, '.gif')) {
			return true;
		} else if(hasSuffix(name, '.webp')) {
			return true;
		}
	}
	return false;
}

export function mergeTwoSorted(arrayOne, arrayTwo, condition) {
	let indexOne = 0
		, indexTwo = 0
		, merged = []
		;
	while (indexOne < arrayOne.length || indexTwo < arrayTwo.length) {
		let itemOne
			, itemTwo
			;
		if (indexOne < arrayOne.length && indexTwo < arrayTwo.length) {
			itemOne = arrayOne[indexOne];
			itemTwo = arrayTwo[indexTwo];
			let result;
			if (typeof condition === "function") {
				result = condition(itemOne, itemTwo);
			} else if (condition === "reverse") {
				result = itemTwo - itemOne;
			} else {
				result = itemOne - itemTwo;
			}
			if (result < 0) {
				merged.push(itemOne);
				indexOne++
			} else if (result > 0) {
				merged.push(itemTwo);
				indexTwo++
			} else {
				merged.push(itemOne);
				indexOne++
				merged.push(itemTwo);
				indexTwo++
			}
		} else if (indexOne < arrayOne.length) {
			merged.push(arrayOne[indexOne]);
			indexOne++;
		} else if (indexTwo < arrayTwo.length) {
			merged.push(arrayTwo[indexTwo]);
			indexTwo++;
		}
	}
	return merged;
}

/*
export function isSipNumber(contact) {
	return validSipRegex.test(contact);
}
*/

// Secure message special user Id
export const validFixedLengthNumericRegex = _validFixedLengthNumericRegex;
export const validMultiFixedLengthNumericRegex = _validMultiFixedLengthNumericRegex;
export const isValidFixedLengthNumeric = _isValidFixedLengthNumeric;
export const isValidMultiFixedLengthNumeric = _isValidMultiFixedLengthNumeric;

export const validPhoneRegex = _validPhoneRegex;

export const validMultiPhonesRegex = _validMultiPhonesRegex;

export const isValidPhoneNo = _isValidPhoneNo;

export const isValidMultiPhones = _isValidMultiPhones;

export const validMultiEmailsRegex = _validMultiEmailsRegex;

export const validEmailRegex = _validEmailRegex;

export const isValidEmail = _isValidEmail;

export const isValidMultiEmails = _isValidMultiEmails;

function isInsideOneAreaTag(areaTag, tag, tagDetail) {
	if (areaTag.id !== tag[tagDetail.index]) {
		return false;
	}
	tagDetail.index++;
	if (tagDetail.index < tagDetail.size) {
		if(!areaTag.children) {
			return false;
		}
		return insideAreaTags(areaTag.children, tag, tagDetail);
	} else if (areaTag.children) {
		return false;
	}
	return true;
}

function insideAreaTags(areaTags, tag, tagDetail) {
	let found = false;
	$.each(areaTags, (i, v) => {
		if (isInsideOneAreaTag(v, tag, tagDetail)) {
			found = true;
			return false;
		}
	});
	return found;
}

export function isInsideAreaTags(areaTags, tag) {
	return insideAreaTags(areaTags, tag, {index: 0, size: tag.length});
}

function findTag(src, id) {
	let found
		, index
		;
	$.each(src, (i, v) => {
		if(v.id === id) {
			found = true;
			index = i;
			return false;
		}
	});
	if(found) {
		return src[index];
	}
	return null;
}

export function getTagName(tags, ids) {
	let names = [];
	$.each(ids, (i, v) => {
		let tag = findTag(tags, v);
		if (tag) {
			names.push(tag.value);
			if(!tag.children) {
				return false;
			} else {
				tags = tag.children;
			}
		}
	});
	return names.join(' / ');
}

function checkIfFound(result, current, value, idNameFields) {
	const id = current[idNameFields.id];
	if (id === value) {
		result.found = [{id, name: current[idNameFields.name]}];
	}
}

export function findIdNameFromArray(array, value, idNameFields, nested) {
	const { id: idField, name: nameField } = idNameFields;
	let result = {};
	$.each(array, (i, v) => {
		if (nested) {
			$.each(v[nested], (j, w) => {
				checkIfFound(result, w, value, idNameFields);
				if (result.found) {
					result.found.push({
						id: v[idField]
						, name: v[nameField]
					});
					return false;
				}
			});
			if (result.found) {
				return false;
			}
		} else {
			checkIfFound(result, v, value, idNameFields);
			if (result.found) {
				return false;
			}
		}
	});
	return result.found;
}

export const moreFileDetail = (file, tz) => update(file, {
	createdAt: {$set: moment().utcOffset(tz).format('YYYY-MM-DD HH:mm')},
});

export function recipientsValue(inputs, recipient) {
	const key = 'update_' + recipient;
	let res = [];
	$.each(inputs[key], (i,v) => {
		res.push(v.value);
	});
	return res;
}

export function getEmailOutParentheses(email) {
	const re = /\((.*)\)/, match = email.match(re);
	if(match) {
		return match[1];
	} else {
		return email;
	}
}

function getUniqueIDAsMap(map, unique) {
	$.each(map, (k,v) => {
		const exist = unique[k];
		if (!exist) {
			unique[k] = true;
		}
	});
	return unique;
}

function getUniqueIDWithLevel(map, level, unique) {
	if (level > 0) {
		$.each(map, (k,v) => {
			unique = getUniqueIDWithLevel(v, level-1, unique);
		});
	} else {
		unique = getUniqueIDAsMap(map, unique);
	}
	return unique;
}

// return array of unique ID for map in the format map[int]map[int]...map[int]int
export function getUniqueID(map, level) {
	let ids = [];
	$.each(getUniqueIDWithLevel(map, level, {}), (k,v) => {
		ids.push(parseInt(k, 10));
	});
	return ids;
}

function sumBasic(key, value, summation) {
	let count = summation[key];
	if (!count) {
		count = value;
	} else {
		count += value;
	}
	summation[key] = count;
	return summation;
}

function sumCountMap(map, returnLevel, summation) {
	$.each(map, (k,v) => {
		if (typeof v !== 'number') {
			const newLevel = returnLevel-1;
			if (returnLevel === 0) {
				let total = 0;
				$.each(sumCountMap(v, returnLevel, {}), (key, count) => {
					total += count;
				});
				summation = sumBasic(k, total, summation);
			} else {
				summation = sumCountMap(v, returnLevel, summation);
			}
		} else {
			summation = sumBasic(k, v, summation);
		}
	});
	return summation;
}

function sumMultiLevelMap(map, ids, returnLevel, summation) {
	if (ids.length > 0) {
		const n = ids[0], typeN = typeof n;
		let validation;
		if (typeN === 'number' || typeN === 'string') {
			validation = mapKey => mapKey == n;
		} else if (n) {
			validation = mapKey => {
				let found;
				$.each(n, (i,v) => {
					if (v == mapKey) {
						found = true;
						return false;
					}
				});
				return !!found;
			};
		} else {
			validation = () => true;
		}
		$.each(map, (k,v) => {
			if (validation(k)) {
				if (typeof v === 'number') {
					summation = sumBasic(k, v, summation);
				} else {
					const newLevel = returnLevel-1;
					if (returnLevel === 0) {
						let total = 0;
						$.each(sumMultiLevelMap(v, ids.slice(1), newLevel, {}),
							(key, count) => {
							total += count;
						});
						summation = sumBasic(k, total, summation);
					} else {
						summation = sumMultiLevelMap(v, ids.slice(1), newLevel,
							summation);
					}
				}
			}
		});
	} else {
		summation = sumCountMap(map, returnLevel, summation);
	}
	return summation;
}

export function mapsSumLevel(map, ids, returnLevel) {
	return sumMultiLevelMap(map, ids, returnLevel, {});
}

// sum all integers base on same key and also selection from 'ids'. Return
// map[int]int. 'ids' is array of integer, string, or array of integer/string.
// If 'ids' element is null or undefined then all key of that level is selected.
// eg: if map[AgentID][AreaID][FolderID][TagID]Count uses
// 'ids' = [null, [1,2], 3], then the summation will sum tag count base on tag
// key from all agents, area ID 1 and 2, and folder ID 3.
export function mapsSum(map, ids) {
	return mapsSumLevel(map, ids, -1);
}

// work like mapsSum but in the end return one integer that sum all count from
// result mapsSum.
export function sumAll(map, ids) {
	let count = 0;
	$.each(mapsSum(map, ids, {}), (k,v) => {
		count += v;
	});
	return count;
}

// window.DBGTest4 = mapsSumLevel;
// window.DBGTest3 = sumAll;
// window.DBGTest2 = mapsSum;
// window.DBGTest1 = getUniqueID;

// get unique address in []string format from addressList in array of object
// with key id and key value.
export function getUniqueAddress(addressList) {
	let seen = {}, addresses = [];
	$.each(addressList, (i,v) => {
		if(!seen[v.id]) {
			addresses.push(v.id);
			seen[v.id] = true;
		}
	});
	return addresses;
}

export const sanitizeHtml = html => {
	var doc = document.createElement('div');
	doc.innerHTML = html;
	return doc.innerHTML;
}

export const sanitizeURL = url => {
	var doc = document.createElement('div');
	doc.innerHTML = url;
	return doc.innerText;
}

export const sanitizeText = text => {
	var entities = [
	['amp', '&'],
	['apos', '\''],
	['#x27', '\''],
	['#x2F', '/'],
	['#39', '\''],
	['#34', '\"'],
	['#47', '/'],
	['lt', '<'],
	['gt', '>'],
	['nbsp', ' '],
	['quot', '"']
	];

	for (var i = 0, max = entities.length; i < max; ++i)
		text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

	return text;
}

export const getTextLengthOnly = (html) => {
	let doc = document.createElement('div');
	doc.innerHTML = html;
	const textOnly = doc.innerText;
	doc = null;
	return textOnly.length;
}

export const truncateHtml = (s, l) => {
	var m, r = /<([^>\s]*)[^>]*>/g,
		stack = [],
		lasti = 0,
		result = '';
		//for each tag, while we don't have enough characters
		while ((m = r.exec(s)) && l) {
			//get the text substring between the last tag and this one
			var temp = s.substring(lasti, m.index).substr(0, l);
			//append to the result and count the number of characters added
			result += temp;
			l -= temp.length;
			lasti = r.lastIndex;
			if (l) {
				result += m[0];
				if (m[1].indexOf('/') === 0) {
					//if this is a closing tag, than pop the stack (does not account for bad html)
					stack.pop();
				}else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
					//if this is not a self closing tag than push it in the stack
					stack.push(m[1]);
				}
			}
		}
		//add the remainder of the string, if needed (there are no more tags in here)
		result += s.substr(lasti, l);
		//fix the unclosed tags
		while (stack.length) {
			result += '</' + stack.pop() + '>';
		}
		return result;
}

export const collectTags = tags => {
	let ts = {
			normal_tags: [],
			delete_tags: []
		};
	$.each(tags, (i,v) => {
		if(v.normal_tags) {
			ts['normal_tags'].push(v.normal_tags);
		}
		if(v.delete_tags){
			ts['delete_tags'].push(v.delete_tags);
		}
	});
	return ts;
}

export const collectUpdateTags = tags => {
	let ts = [];
	$.each(tags, (i,v) => {
		$.each(v, (j,w) => {
			ts.push(w);
		});
	});
	return ts;
}

export const getErrandIcons = (type, isChat, isPm, sourceId) => {
	if(isChat || type == Workflow.Errand.SERVICE_CHAT){
		switch (sourceId) {
			case Workflow.Errand.SERVICE_FACEBOOK:
				return <i title={I("Facebook private message")} className="icon-v5-messenger" />;
			case Workflow.Errand.SERVICE_TWITTER:
				return <i title={I("Twitter direct message")} className="icon-v5-twitter-direct-message" />;
			case Workflow.Errand.SERVICE_LINE:
				return <i title={I("Line chat message")} className="icon-v5-line" />;
			case Workflow.Errand.SERVICE_WHATSAPP:
				return <i title={I("WhatsApp chat message")} className="icon-v5-whatsapp" />;
			case Workflow.Errand.SERVICE_TELEGRAM:
				return <i title={I("Telegram chat message")} className="icon-telegram" />;
			case Workflow.Errand.SERVICE_VIBER:
				return <i title={I("Viber chat message")} className="icon-viber" />;
			case Workflow.Errand.SERVICE_INSTAGRAM:
				return <i title={I("Instagram direct message")} className="icon-instagram-dm" />;
			default:
				return <i className="icon-v5-chat" />;
		}
	}else{
		//For chat that already closed that no longer become chat
		//for example FB Messenger
		if(isPm){
			if(type ===  Workflow.Errand.SERVICE_FACEBOOK){
				return <i title={I("Facebook private message")}
					className="icon-v5-messenger" />
			}else if(type === Workflow.Errand.SERVICE_TWITTER){
				return <i title={I("Twitter direct message")}
					className="icon-v5-twitter-direct-message" />
			}else if(type === Workflow.Errand.SERVICE_VKONTAKTE){
				return <i className="icon-v5-vk-messenger" />
			}else if(type === Workflow.Errand.SERVICE_LINE){
				return <i className="icon-v5-line" />
			}else if(type === Workflow.Errand.SERVICE_WHATSAPP){
				return <i className="icon-v5-whatsapp" />
			}else if(type === Workflow.Errand.SERVICE_TELEGRAM){
				return <i className="icon-telegram" />;
			}else if(type === Workflow.Errand.SERVICE_VIBER){
				return <i className="icon-viber" />;
			}else if(type === Workflow.Errand.SERVICE_INSTAGRAM){
				return <i className="icon-instagram-dm" />;
			}else if(type === Workflow.Errand.SERVICE_TRUSTPILOT){
				return <i className="icon-star-full" />;
			}
		}else{
			return <i className={DEFAULT_ERRAND_TYPE_ICONS[type]} />
		}
	}
}

export const IsContainObject = (obj, list) =>{
	var x;
	for (x in list) {
		if (list.hasOwnProperty(x) && list[x] === obj) {
			return true;
		}
	}
	return false;
}

export const getFBTypingStatus = (state) =>{
	if(typeof state.chat.socket !== 'undefined'){
		let status = state.chat.socket.fbTypingStatus[state.app.errand.currentChatErrandId];
		if(typeof  status === 'undefined'){
			return 0
		}
		return status
	}
	return 1
}
export const getCurrentChatTemplate = (state) =>{
	if(typeof state.chat.socket !== 'undefined'){
		let templ = state.chat.socket.template[state.app.errand.currentChatErrandId];
		if(typeof  templ === 'undefined' && templ == 0){
			return 0
		}
		return templ
	}
	return 0
}
export const getCurrentChatErrand = (state) => {
	let chat;
	const wf = state.app.workflow
		, erd = state.app.errand;
	$.each(wf.errandListChat, (i, ce) => {
		if (erd.currentChatErrandId == ce.errand.id) {
			chat = ce;
			return false;
		}
	});
	if(!chat) {
		const cerd = state.chat.closedChatErrand.data;
		if (cerd.data && (erd.currentChatErrandId == cerd.data.errand.id)) {
			chat = cerd.data;
		}
	}
	return chat;
}

export const stripHTML = (html) => {
	var tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

export const removeHTMLTags = (html) => {
	if (typeof html === 'undefined' || html.length === 0) {
		return '';
    }
	var newHtml = html.replace(/<\/?[^>]+(>|$)/g, "");
	return newHtml.replace(/&nbsp;/g, " ");
}

export const defaultPhoto = (isAgent) => {
	return (
		<svg className={isAgent ? 'icon-agent' : 'icon-client'}>
			<svg version="1.1" id="client" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 19.1 19.1" xmlSpace="preserve">
				<g>
					<circle cx="9.6" cy="9.5" r="9.4"/>
					<g>
						<circle cx="9.5" cy="7.5" r="2.7"/>
							<path d="M11.7,9.4c-0.6,0.5-1.4,0.7-2.2,0.7c-0.8,0-1.5-0.3-2.2-0.7c-1.4,0.9-1.8,2.7-1.8,4.8h7.8
								C13.5,12.2,13.1,10.4,11.7,9.4z"/>
					</g>
				</g>
			</svg>
		</svg>
	);
}

export const defaultAvatar = (isAgent) => {
	return (
		<div className={"default profile-avatar " + (isAgent ? 'icon-agent' : 'icon-client')}>
			<i className="icon-user-fill"></i>
		</div>
	)
}

const urlRegex = /(>|^)(https?:\/\/[^ \<]+)/i;
const urlRegex2 = /<a\s+href="https?:\/\/.*">/g;

export const linkifyUrls = (html) => {
	var res
	, i
	;

	res = Array.prototype.slice.call(html.split(/(\&nbsp;|\s)+/g))

	for(i=0; i<res.length; i++) {
		res[i] = res[i].replace(urlRegex, function(match, p1, p2) {
			let url = p2
			, lastChunk = ""
			;
			while (url && url.length > 1 && (
				url[url.length-1] == '.' ||
					url[url.length-1] == ',' ||
					url[url.length-1] == '"' ||
					url[url.length-1] == ']' ||
					url[url.length-1] == ')'
			)) {
				lastChunk += url[url.length-1];
				url = url.substring(0, url.length-1);
			}
			let removeExtraTagRegex = /(<([^>]+)>)/ig;
			let linkSrc = url.replace(/"/g, '\\x22');
			let sanitizeLinkSrc = linkSrc.replace(removeExtraTagRegex, "");
			let sanitizedURL = sanitizeURL(sanitizeLinkSrc);
			checkForImage(sanitizedURL);
			return [ p1
					, '<a class="imgPreviewLink" id="'+sanitizeLinkSrc+'" href="' + sanitizeLinkSrc + '" target="_blank">' + url + '</a>'
					, lastChunk
				].join('');
		});
	}
	let newHtml = res.join(' ');
	newHtml = newHtml.replace(urlRegex2, function(match, offset) {
		if(match.includes("target=")){
			return match;
		}
		let replaced = match.replace(">", ' target="_blank">');
		return replaced;
	})
	return newHtml;
}

function checkForImage(imageSrc) {
	var img = new Image();
	try {
		img.src = imageSrc;
		img.onload = function() {
			var elem = document.getElementById(imageSrc);
			if(elem) {
				document.getElementById(imageSrc).setAttribute("data-lightbox", "imgPreviewLink"+imageSrc);
			}
		}
	} catch(err) {
		return false;
	}
}

export const renderFileHtml = (url, fileName) => {
	let ename = escape(fileName)
	, inner = fileName
	;

	if (isImageFilename(fileName)) {
		inner = [
			'<img'
				, ' src="', url, '"'
				, ' alt="', escape(fileName), '"'
				, ' style="max-width:200px;max-height:200px"'
			, '/>'
		].join('');
	}
	return [
		'<a'
			, ' title="', escape(fileName), '"'
			, ' href="', url, '">'
			, inner
		, '</a>'
	].join('') ;
}

const convert = (result, areaTags, tag) => {
	$.each(areaTags, (i,v) => {
		if(v.tagId === tag[result.current]) {
			result.converted.push(v.id);
			result.current++;
			if(result.current >= result.size) {
				return false;
			}
			convert(result, v.children, tag);
			return false;
		}
	});
}

const convertOneTagIdToTagLevelId = (areaTags, tag) => {
	const tagSize = tag.length;
	if(!tagSize) {
		return;
	}
	let result = {
		converted: [],
		size: tagSize,
		current: 0
	};
	convert(result, areaTags, tag);
	return result.converted;
};

export const convertTagIdToTagLevelId = (areaTags, tags) => {
	let convertedTags = [];
	$.each(tags, (i,v) => {
		const oneArrayTag = convertOneTagIdToTagLevelId(areaTags, v);
		if(oneArrayTag && oneArrayTag.length) {
			convertedTags.push(oneArrayTag);
		}
	});
	return convertedTags;
};

export const getChatArea = (chat) => {
	let area;
	$.each(initialChatData.chatAreas, (i, v) => {
		if (chat.errand.data.area == v.Id) {
			area = v;
			return false;
		}
	});
	return area;
};

export const getChatTagLevels = (chat) => {
	let chatArea = getChatArea(chat);
	let normal_tags = chatArea && chatArea.normal_tags ? chatArea.normal_tags : [];
	return convertTagIdToTagLevelId(normal_tags, chat.tags);
}

export const flattenTagLevels = (tagLevels) => {
	let tags = [];
	$.each(tagLevels, (i, v) => {
		$.each(v, (j, tagLevelId) => {
			tags.push(tagLevelId);
		});
	});
	return tags;
}

export const getNotificationIcon = (msgText) => {
	switch(msgText) {
		case "MsgChatNew": // Chat
		case "MsgChatInvite":
		case "MsgChatMessage":
			return <i className={"icon-quote"}></i>

		// TODO icon for each type of notification message
		case "MsgErrandOpenGroupErrand": // Errand
		case "MsgReturnToInbox":
		case "MsgNewAssigedErrand":
		case "MsgNewVoiceErrand":
		case "MsgVoiceErrandOutbound":
		case "MsgVoiceOutboundEnd":
		case "MsgErrandOwnerWorking":
		case "MsgInternalMessage": // Internal message
		case "ForwardErrandToAgent":
		case "ForwardErrandsToAgent":
			return <i className={"icon-bell"}></i>
		case "MsgVoiceCallbackReminder":
		case "MsgNewVoiceCallback":
			return <i className={"icon-call-back"}></i>
		case "MsgCollaborationRequest": // Collaboration
		case "MsgCollaborationReply":
		case "MsgCollaborationReplyUpdate":
		case "MsgSystemExportCompleted": // System
		case "MsgShareReport": // Report
		case "MsgWhatsappMessageExpire": //whatsapp message expire
			return <i className={"icon-bell"}></i>
		case "MsgSocialMediaConnectionErr":
			return <i className={"icon-quote"}></i>
		default:
			return <i className={"icon-bell"}></i>
	}
}

export const getNotificationMessage = (msg) => {
	let message = ""
	switch(msg.text) {
		// Chat
		case "MsgChatNew":
			message = I("Chat request #{errand} was sent to you by {from}");
			break;

		case "MsgChatInvite":
			message = I("#{errand}: {from} invited you to a chat");
			break;

		case "MsgChatMessage":
			message = I("#{errand}: {from} sent you a chat message");
			break;

		// Errand
		case "MsgErrandOpenGroupErrand":
			message = I("Errand #{errand} was opened together with {related_errands}");
			break;

		case "MsgReturnToInbox":
			message = I("Errand #{errand} was returned to All errands by {from}");
			break;

		case "MsgNewVoiceErrand":
			message = I("A new voice errand #{errand} from {from}");
			break;

		case "MsgVoiceErrandOutbound":
			message = I("Voice errand #{errand} requires paging to {outdialNumber}. Perform paging?");
			break;

		case "MsgVoiceOutboundEnd":
			message = I("Call has ended for errand #{errand}");
			break;

		case "MsgVoiceTagErrand":
			message = I("Please tag and close errand #{errand}");
			break;

		case "MsgVoiceCallbackReminder":
			message = I("Reminder: You have a callback request #{errand} at {timeToCall}. Please be prepared for the call.");
			break;

		case "MsgNewVoiceCallback":
			message = I("You have a new callback errand assigned #{errand}");
			break;

		case "MsgErrandOwnerWorking":
			message = I("#{errand}: {agent} is currently working on the errand");
			break;

		case "MsgNewAssigedErrand":
			message = I("You have been assigned an errand #{errand}");
			break;

		// Internal message
		case "MsgInternalMessage":
			message = I("{from} sent you an internal message");
			break;

		// Collaboration
		case "MsgCollaborationRequest":
			message = I("#{errand}: {from} sent you a new internal collaboration request, thread #{eeThread}, query #{eeQuery}");
			break;

		case "MsgCollaborationReply":
			message = I("#{errand}: {from} replied to your collaboration request, thread #{eeThread}, query #{eeQuery}");
			break;

		case "MsgCollaborationReplyUpdate":
			message = I("#{errand}: {from} updated collaboration reply #{eeQuery} thread #{eeThread}");
			break;

		// System
		case "MsgSystemExportCompleted":
			message = I("Export '{export}' is ready, {file}");
			break;

		case "MsgSystemWaitingCount":
			message = I("You have {count} notifications waiting");
			break;

		// Report
		case "MsgShareReport":
			message = I("{from} shared a report with you");
			break;
		//whatsapp message expire
		case "MsgWhatsappMessageExpire":
			message = I("Errand #{errand} is close to the 24 hours reply restriction of the WhatsApp business API and will soon expire.");
			break;
		case "MsgSocialMediaFailedDelivery":
			message = I("Failed to send the errand #{errand}. Please check the errand details and try again later..");
			break;
		case "MsgSocialMediaConnectionErr":
			message = I("Failed to authenticate your {channel} page ID {pageId}, name: {pageName}");
			break;
		case "ForwardErrandToAgent":
			message = I("Agent {agent} forwarded errand {errand} to you at {date}.");
			break;
		case "ForwardErrandsToAgent":
			message = I("Agent {agent} forwarded errands {errands} to you at {date}.");
			break;
		case "MsgAccountsErr":
			message = I("Failed to authenticate your {channel} name: {accountName}");
			break;
		case "ErrandPostponedNotifMsg":
			message = I("Reminder: Postpone time is up for the postponed errand #{errand}");
			break;
		case "MsgAnnouncement":
			message = I("Announcement : {subject}");
			break;
		default:
			message = msg.text;
	}
	for (let key in msg) {
		if (msg.hasOwnProperty(key)) {
			if (key === "text") continue;
			message = message.replace("{"+key+"}", msg[key])
		}
	}
	return message;
}

export const dataURItoBlob = (dataURI) => {
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else
		byteString = unescape(dataURI.split(',')[1]);

	let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	let ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ia], {type:mimeString});
}

export function positiveNumber(data) {
	if (typeof data !== "number" || !(data > 0)) {
		return 0;
	}
	return data;
}

export const getHours = () => {
	let hours = [];
	for (let i = 0; i < 24; i++) {
		let hoursObj = {};
		if(i < 10){
			hoursObj = {'id': i,  'name' : ('0'+i).toString() };
		}else{
			hoursObj = {'id': i,  'name' : i.toString() };
		}
		hours.push(hoursObj);
	}
	return hours;
}
export const getMinutes = () => {
	let minutes = [];
	for (let i = 0; i < 60; i++) {
		let minutesObj = {};
		if(i < 10){
			minutesObj = {'id': i,  'name' : ('0'+i).toString() };
		}else{
			minutesObj = {'id': i,  'name' : i.toString() };
		}
		minutes.push(minutesObj);
	}
	return minutes;
}

export function removePM(s) {
	var pmMarker = '{pm}';
	if(typeof s === 'string') {
		return s.replace(pmMarker, '');
	}
	return s;
}

export function getAppendAnswer(currentAnswer, appendAnswer) {
	currentAnswer = isEmptyHTML(currentAnswer) ? "": currentAnswer;
	return currentAnswer + appendAnswer;
}

const INVALID_EXTERNALQUEUE = "undefined";

const getExtQueueTypeBase = externalqueue => {
	if(typeof externalqueue === "undefined" ||
		externalqueue.isExternal == false){
		return "";
	}

	if(externalqueue.solidus == true ){
		return SOLIDUS;
	} else if(externalqueue.puzzel == true){
		return PUZZEL;
	} else if(externalqueue.clearinteract == true){
		return CLEARIT;
	} else if(externalqueue.enghouse == true){
		return ENGHOUSE;
	} else if(externalqueue.zisson == true) {
		return ZISSON;
	} else if(externalqueue.telavox == true) {
		return TELAVOX;
	}
	return INVALID_EXTERNALQUEUE;
};

const memoizedGetExtQueueType = memoizeOne(getExtQueueTypeBase);

export function getExtQueueType() {
	return memoizedGetExtQueueType(externalqueue);
}

export function isValidTelavoxUrl(theUrl){
	if(typeof features["telavox.external-valid-url"] === 'undefined'){
              return true;
	}
	for(let oneUrl of features["telavox.external-valid-url"]){
		if( oneUrl == theUrl){
			return true;
		}
	}
	return false
}

export function isTelavox() {
	if(typeof externalqueue === "undefined"){
			return false;
	}
	return (externalqueue.telavox == true &&
		externalqueue.isPostMessage == true);
}

//used to check if window is opened via /external-open call
//not used to check if errand is opened via external-open URL
export function isExternalOpen() {
	if(typeof externalqueue === "undefined"){
			return false;
	}
	if(externalqueue.isExternalOpen == false){
			return false;
	}
	return externalqueue.telavox == true;
}

export function isTelavoxEnabled() {
	if(typeof features === "undefined"){
			return false;
	}
	return features["telavox"] == true;
}

const isValidIntegrationBase = externalqueue => {
	if (!externalqueue || externalqueue === INVALID_EXTERNALQUEUE) {
		return false;
	}
	return true;
};

const memoizedIsValidIntegration = memoizeOne(isValidIntegrationBase);

export function isValidIntegration(state) {
	return memoizedIsValidIntegration(memoizedGetExtQueueType(externalqueue));
}

export function handleCloseWindow(state) {
	const qType = getExtQueueType();
	if (qType.length > 0) {
		if (qType != SOLIDUS) {
			try {
				window.close();
			} catch (e) {
				console.log("dbg: unable to close browser window: ", e);
			}
		} else {
			window.location.href = "about:blank";
		}
	}
}


// Usage, remove only single character from a string
// For example, "Internal Comment", will only remove the
// first "m" and it will result in a splitted array of text of
// ["Internal Co", "ment"];
export function removeCharOnce(str, char){
	let components = str.split(char);
	return([components.shift(), components.join(char)]);
}

export function flatMap(array, fn) {
	var result = [];
	for (var i = 0; i < array.length; i++) {
		var mapping = fn(array[i]);
		result = result.concat(mapping);
	}
	return result;
}

export function getHotkeyChar(set, act) {
	for(var i in set){
		let hk = set[i];
		if(hk.action == act){
			return hk.key;
		}
	}
}

export function errandHotkeyChar(action) {
	return getHotkeyChar(ERRAND_HOTKEYS, action);
}

export function workflowHotkeyChar(action) {
	return getHotkeyChar(WORKFLOW_HOTKEYS, action);
}

export function internalChatCollabotionHotkey() {
	return errandHotkeyChar(TOGGLE_COLLAB);
}

const PLEASE_WAIT_WHILE = I("Please wait while {WAIT_REASON}");

export function pleaseWaitString(waitReason) {
	return PLEASE_WAIT_WHILE.replace("{WAIT_REASON}", waitReason);
}

export function createMarkup(str){
	return {__html: str};
}

// This functions goal is to return (X) number of rows that can fit in a parent/body of a container.
// Mostly used to DYNAMICALLY fit table rows according to the space of the parent viewport.
// Calculation done by including viewport height, a single row's height and finally other
// offsets (eg.padding, header, footer, thead etc...)
export const calculateRowsToFitInContainer = (viewHeight, rowHeight, offsets) => {
	const remainingHeight = viewHeight - offsets;
	const numberOfItems = Math.floor(remainingHeight/rowHeight);
	return numberOfItems;
}

export function getListSizeBasedOnHeight() {
	if(window.innerHeight < 650) {
		return 10;
	}else if(window.innerHeight < 900){
		return 12;
	}else if(window.innerHeight < 1000) {
		return 15;
	}else if(window.innerHeight < 1300) {
		return 25;
	}else if(window.innerHeight < 1400) {
		return 35;
	}else {
		return 50;
	}
}

function getArrayOfKeysWithCondition(condition, arr, ...keys) {
	const results = [];
	$.each(keys, i => {
		results.push([]);
	});
	if (!arr.length) {
		return results;
	}
	return arr.reduce((a, v) => {
		$.each(keys, (i, w) => {
			const value = v[w];
			if (condition(value)) {
				a[i].push(value);
			}
		});
		return a;
	}, results);
}

export function getArrayOfKeys(...args) {
	return getArrayOfKeysWithCondition(() => true, ...args);
}

function getUniqueValueOfArrayOfKeys(...args) {
	let exist = {}
	return getArrayOfKeysWithCondition(value => {
		if (exist[value]) {
			return false;
		}
		exist[value] = true;
		return true;
	}, ...args);
}

export function serializeArrayOfArray(arrOfArr) {
	const results = [];
	if (!arrOfArr.length) {
		return results;
	}
	return arrOfArr.reduce((a, v) => {
		a.push(v.join(","));
		return a;
	}, results);
}

export function serializeArrayOfKeys(...args) {
	return serializeArrayOfArray(getArrayOfKeys(...args));
}

export function serializeUniqueValueOfArrayOfKeys(...args) {
	return serializeArrayOfArray(getUniqueValueOfArrayOfKeys(...args));
}

export function formatValueToNameObj(list, idKey, nameKey){
	let r = [], idField = "id", nameField = "value";
	$.each(list, (i, v) => {
		if(idKey) {
			idField = idKey;
		}
		if(nameKey) {
			nameField = nameKey;
		}
		if(idKey && idKey === 1){
			r.push({id: i, name: v})
		} else{
			r.push({id: v[idField], name: v[nameField]})
		}
	});
	return r;
}

export const FormatOptions = (list) => {
	let r = [];
	$.each(list, (i, v) => {
		r.push({id: v.Id, name: v.Name})
	});
	return r;
}

export const isJSONObject = (text) => {
	try{
		if(typeof JSON.parse(text) === "object"){
			return true;
		}else{
			return false;
		}
	} catch(error){
		return false;
	}
}

export const reviewErrandURL = (id, cipherKey) => REVIEW + "/" + cipherKey + "/" + id;

export const addressValidation = _addressValidation;

export const emailValidation = _emailValidation;

export function isExternalExpert(view) {
	return view === M_EXTERNALEXPERT || view === M_MY_EXTERNALEXPERT;
}

export const isAccount = (view) => {
	switch(view) {
		case M_ACCOUNTS:
		case M_ACC_EMAIL:
		case M_ACC_LINE:
		case M_ACC_FACEBOOK:
		case M_ACC_INSTAGRAM:
		case M_ACC_TWITTER:
		case M_ACC_LINKEDIN:
		case M_ACC_JIRA:
		case M_ACC_HUBSPOT:
		case M_ACC_MSTEAMS:
		case M_ACC_GOOGLEREVIEW:
		case M_ACC_GOOGLECHAT:
		case M_ACC_GOOGLEPLAY:
		case M_ACC_SMPP:
		case M_ACC_TRUSTPILOT:
		case M_ACC_TELEGRAM:
		case M_ACC_WHATSAPP:
		case M_ACC_TWILIO:
		case M_ACC_VIBER:
		case M_ACC_SLACK:
		case M_ACC_YOUTUBE:
			return true;
		default:
			return false;
	}
}
export const isFileArchive = view => view === ADMIN_VIEW_MAP["filearchive"];

export const isSalutation = view => view === M_MY_SALUTATION || view === M_SALUTATION;

export const isSignature = view => view === M_MY_SIGNATURE || view === M_SIGNATURE;

export const isQuickReply = view => view === M_QUICK_REPLY;

export const isTemplate = view => view === M_TEMPLATE || view === M_TEMPLATE_WHATSAPP;

export const isSST = view => isSalutation(view) || isSignature(view) || isTemplate(view);

export const isStandardView = view => isSST(view) || view === M_REVIEW_KEYWORDS;

export const isInternalState = view => view === M_ERRANDINTERNALSTATE;

export const isRoutingView = view => view === M_ROUTING_KEYWORDS || view === M_ROUTING_AUTOTAGS || view === M_ROUTING_SIP;
export const isBlacklist = view => view === M_BLACKLIST;
export const isBlacklistResp = view => view === M_BLACKLISTRESP;
export const isToolManager = view => view === M_LLM_TOOL_MANAGER;
export const isAdminList = view => view !== M_CHATWIDGETDL && view !== M_FAQWIDGETDL && view !== M_VOICEWIDGETDL;

export function isValidPassword(txt, minReq) {
	let pass = true;
	if(txt.length !== 0) {
		if (minReq){
			if(txt.length < 5){
				pass = false;
			}else{
				if (/\d/.test(txt) && /[A-Z]/.test(txt)) {
					pass = true;
				}else{
					pass = false;
				}
			}
		}
	}
	return pass;
}

export const isDesktopMode = () => {
	let desktopMode = false;
	if(window.innerWidth > 1400) {
		desktopMode = true;
	}
	return desktopMode;
}

export const isIOS = () => {
	//this returns true if the string "iPhone/ipad/ipod" is seen in the browser user agent text, or false if the text is not found.
	let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
	return iOS
}

export const getNameFromID = (id, options) => {
	let name;
	$.each(options, (i,v) => {
		if (v.id == id) {
			name = v.name;
			return false;
		}
	});
	if (!name) {
		name = 'Unknown';
	}
	return name;
}

export const getValueFromID = (id, value, options) => {
	let val;
	$.each(options, (i,v) => {
		if (v.id == id) {
			val = v[value];
			return false;
		}
	});
	if (!val) {
		val = 'Unknown';
	}
	return val;
}

export const isChartGrid = layout => layout === CL_OVERVIEW && PC_NEW_CHART_LAYOUT;

export function msToHMS( ms ) {
	// 1- Convert to seconds:
	var seconds = ms / 1000;

	// 2- Extract hours:
	var hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
	seconds = seconds % 3600; // seconds remaining after extracting hours

	// 3- Extract minutes:
	var minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute

	// 4- Keep only seconds not extracted to minutes:
	seconds = seconds % 60;
	seconds = Math.ceil(seconds);

	hours = (hours < 10) ? "0" + hours : hours;
	minutes = (minutes < 10) ? "0" + minutes : minutes;
	seconds = (seconds < 10) ? "0" + seconds : seconds;
	var hms = hours+":"+minutes+":"+seconds;
	return hms;
}

export const closeStatusString = statusId => statusId === CLOSED ? 'Closed' : ''

export function checkInvalidCharacter(s) {
	return /(^\u0020|\u0020$|^\u00a0|\u00a0$)/i.test(s);
}

export function removeInvalidCharacter(s) {
	return s.replace(/^\u0020+/i, '').replace(/\u0020+$/i, '').replace(/^\u00a0+/i).replace(/\u00a0+$/i, '');
}

function tryFormatPhoneIntl(phoneStr) {
	/*
	this function tries format phone string	to
	international standard if possible, else it remains as it is
	*/
	if(isPossiblePhoneNumber(phoneStr)){
		return phoneStr = formatPhoneNumberIntl(phoneStr);
	} else {
		return phoneStr
	}
}

export const cleanChannelIdentifier = (serviceName, title) => {
	//this function removes channel prefix from the `title`
	let cleaned = title;
	if(typeof title !== 'undefined'){
	$.each(ch, (i,v) => {
		if(v.capName === serviceName){
			//format number to look nicer on whatsapp
			if(serviceName === "WhatsApp" || serviceName === "WhatsApp (manual)"){
				let wAppNo = title.replace(v.prefix, "+");
				return cleaned = tryFormatPhoneIntl(wAppNo);
			}
			return cleaned = title.replace(v.prefix, "");
		}
	});
	}
	return cleaned;
}

export function notifyOS(title, msg, timeSent){
	if(features.browserOsNotify !== true) {
		return;
	}
	if(!Notification){
		console.info("browser does not support os notification");
	}
	if(typeof timeSent !== 'undefined' && isNaN(timeSent) == false &&
		timeSent > 0){
		let date = new Date();
		let diffSeconds = Math.round(date.getTime()/1000) - timeSent;
		//ignore anything older than 30 mins
		if(diffSeconds > 60*30){
			return;
		}
	}
	if (Notification.permission !== 'granted'){
		Notification.requestPermission();
	}
	let notification = new Notification(title,{
		body: msg,
		});
	console.info(msg);
}

export function isSipConnected(theState) {
	if(theState == SIP_DISCONNECTED || theState == SIP_UNREGISTER ||
		theState == SIP_REGISTER_FAIL){
		return false;
	}
	return true;
}

export function windowPopupCenter(url, w, h, otherParams) {
	// Fixes dual-screen position                         Most browsers      Firefox
	let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
	let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

	let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

	let left = ((width / 2) - (w / 2)) + dualScreenLeft;
	let top = ((height / 2) - (h / 2)) + dualScreenTop;

	let defaultParams = "status=no,toolbar=no";
	let params = otherParams ? otherParams : defaultParams;
	let newWindow = window.open(url, "_blank",
		`
	width=${w},
	height=${h},
	top=${top}',
	left=${left},
	${params}
	`
	);

	// Puts focus on the newWindow
	if (window.focus) {
		newWindow.focus();
	}
}

export function removeDupeByKey(arr, key) {
	const temp = arr.map(el => el[key]);
	return arr.filter((el, i) =>
		temp.indexOf(el[key]) === i
	);
}

export function getRandomString(length) {
	let text = "";
	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const possibleLength = possible.length;
    for ( let i = 0; i < length; i++ ) {
        text += possible.charAt(Math.floor(Math.random() * possibleLength));
    }

    return text;
}

//must have
//1. lower case alphabet
//2. upper case alphabet
//3. digits
//4. symbols
//5. 8 characters min
export function simplePasswordCheck(password) {
	let testCheck = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
	return testCheck.test(password);
}

//Export json object into a file to be downloaded
export const saveTemplateAsFile = (filename, data) => {
	const blob = new Blob([JSON.stringify(data, null, "\t")], { type: "text/json" });
	const link = document.createElement("a");

	link.download = filename;
	link.href = window.URL.createObjectURL(blob);
	link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

	const evt = new MouseEvent("click", {
		view: window,
		bubbles: true,
		cancelable: true,
	});

	link.dispatchEvent(evt);
	link.remove()
};

export function moveObjectInArray(arr, sourceIndex, targetIndex) {
	if (sourceIndex < 0 || sourceIndex >= arr.length || targetIndex < 0 || targetIndex >= arr.length) {
		console.error("Invalid source or target index.");
		return;
	}
	const [movedObject] = arr.splice(sourceIndex, 1);
	arr.splice(targetIndex, 0, movedObject);
	return arr;
}

export function checkIfChatNotEmpty() {
	const placeholder = CHAT_CKE_PLACEHOLDER_TEXT;
	const editor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
	if(editor){
		let a = editor.document.getBody().getText();
		if(placeholder){
			a = a.replace(placeholder,'');
		}
		a = a.replace(/\s/g,'');
		a = a.replace(/(\r\n|\n|\r)/gm,'');
		a = a.replace(/[\u21B5|\u000A]/g, "");
		a = a.replace(new RegExp('<div></div>','g'),'');
		if(a == ""){
			let b = editor.getData();
			b = b.replace(/\s/g,'');
			b = b.replace(new RegExp('&nbsp;', 'g'),'');
			b = b.replace(new RegExp('<div></div>','g'),'');
			return b != ""
		}
		return a != "";
	}
	return false;
}

export const isTextInGsm7 = (smsText) => {
	var gsm7CharSet="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxy0123456789\"#¤%&'()*+,-./:;<=>?¡@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}\\[~]|€ÆæßÉ!<=>?¡ÄÖÑÜ§¿äöñüà\r\n\t\u00A0 "
	return [...smsText].every(c => gsm7CharSet.includes(c));
}

export const charCountPerSms = (isGsm7, shorterUCS2) => {
	//Default encoding is gsm7
	if(isGsm7) {
		return 150;
	}
	var WorkflowSettings = localStorage.getItem("WorkflowSettings");
	//sms providers like puzzel intepret the spec differently and only
	//allow 67 bytes
	if(shorterUCS2 == true){
		return 67;
	}
	//return 67 * 2 for UTF-8. UTF-16 only allows 67 chars
	return 67 * 2;
}

export const langFromAutoClassify = (classify) => {
	let errandLanguage = "";
	if (typeof classify !== 'undefined' && classify != '') {
		const moodAndLanguageClassify = {};
		const validJSONClassify = JSON.parse(classify);
		for(var prop in validJSONClassify){
			if (Object.prototype.hasOwnProperty.call(validJSONClassify,
				prop)) {
					if(prop == "language" || prop == "language-score" || prop == "sentiment" || prop == "sentiment-score"){
						moodAndLanguageClassify[prop] = validJSONClassify[prop];
					}
			}
			if(moodAndLanguageClassify["language"]){
                  errandLanguage = moodAndLanguageClassify["language"];
              }
		}
	}
	return errandLanguage;
}
export const smsCount = (smsText, shorterUCS2) => {
	let isGsm7 = isTextInGsm7(smsText);
	let charCount = charCountPerSms(isGsm7, shorterUCS2);
	let count = 0;
	//replace double new lines with single new line
	smsText = smsText.replace(/(\n)\1+/g, "$1");
	smsText = smsText.replace(/[\u202F\u00A0]/, "");
	let encodedLength = (new TextEncoder().encode(smsText)).length;
	if(encodedLength > 0) {
		count = Math.ceil(encodedLength / charCount);
	}
	return count;
}

export const filePathPrefix = () => {
	let url = webRoot
	if (cflag.IsActive("2024-04-01.CEN-1479.new.service.serve.file.attachment") ) {
		url = "/s/"+space+CentionFile;
	}
	return url
}

function stripHtmlTags(text) {
    // Remove HTML tags using a regular expression
    return text.replace(/<[^>]*>/g, '');
}

function tokenize(text) {
    // Split text into words
    return text.split(/\s+/);
}

// Compare two texts and return the percentage of difference
export function compareText(text1, text2) {
    const plainText1 = stripHtmlTags(text1);
    const plainText2 = stripHtmlTags(text2);
    const tokens1 = tokenize(plainText1);
    const tokens2 = tokenize(plainText2);

    const maxLength = Math.max(tokens1.length, tokens2.length);
    let diffCount = 0;

    for (let i = 0; i < maxLength; i++) {
        if (tokens1[i] !== tokens2[i]) {
            diffCount++;
        }
    }
    const differencePercentage = (diffCount / maxLength) * 100;
    return differencePercentage.toFixed(2);
}
